import React from "react"
import Helmet from "react-helmet"
import ReactBreakpoints from "react-breakpoints"
import breakpoints from "../utils/breakpoints"
import { useStaticQuery, graphql } from "gatsby"

// import UI elements
import SiteHeader from "../ui/SiteHeader"
import NavMain from "../ui/NavMain"
import SiteFooter from "../ui/SiteFooter"
import WiedensimRandom from "../content/wiedensim/WiedensimRandom"
import PageTransition from "./PageTransition"

// debuger
import BuildDebug from "../components/BuildDebug"
import PrivacyNotices from "../components/PrivacyNotices"

// all style
import "../style/App.scss"

// favicon
import faviconApple from "../favicon/apple-touch-icon.png"
import favicon16 from "../favicon/favicon-16x16.png"
import favicon32 from "../favicon/favicon-32x32.png"
import safariPinTab from "../favicon/safari-pinned-tab.svg"


export default function Layout({ children }) {
  const data = useStaticQuery(
    graphql`
      query IndexQuery {
        offices: allContentfulOffices(
          sort: { fields: [order], order: ASC }
          filter: { node_locale: { eq: "en-US" }, venture: { eq: false } , officeName: {eq: "Delhi"}}
        ) {
          edges {
            node {
              id
              ...officeFragment
            }
          }
        }
        wiedensim: allContentfulWiedenism {
          edges {
            node {
              id
              wiedensim
            }
          }
        }
      }
    `
  )
  return (
    <>
      {/* favicons */}
      <Helmet>
          <link rel="apple-touch-icon" href={faviconApple} />
          <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
          <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
          <link rel="mask-icon" href={safariPinTab} color="#616161" />
          <meta name="msapplication-TileColor" content="#ffffff" />
          <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <ReactBreakpoints breakpoints={breakpoints}>
        <PageTransition />
        <SiteHeader />
        <NavMain offices={data.offices} />
        <main className="main">
          {children}
          <WiedensimRandom data={data.wiedensim} />
        </main>
        <PrivacyNotices />
        <SiteFooter offices={data.offices} />
        {process.env.GATSBY_BRANCH === `preview` && <BuildDebug />}
      </ReactBreakpoints>
    </>
  )
}


