import React from 'react'
import { Link } from 'gatsby'

import NavItems from './NavItems'
import SocialLinks from './SocialLinks'
import Offices from "../content/offices/Offices"
import OfficeAddress from "../content/offices/OfficeAddress"

export default function SiteFooter(props) {
    return (
        <footer id="footer" className="site-footer tohide">
            <div className="container-fluid site-footer__content">
                <div className="row">
                <aside className="site-footer__offices col-lg-4">
                    <div className={`contact__preview alternateTempContact mb-4`}>
                        <span className="contact__type text-muted">Delhi Office</span>
                        <span className="contact__fullname text-dark">
                          17/48, Malcha Marg<br/>
                          Diplomatic Enclave<br/>
                          New Delhi 110021 India<br/>
                          +91 11 6136 9595<br/>
                        </span>
                    </div>
                </aside>
                <aside className="site-footer__offices col-lg-4">
                    <div className={`contact__preview alternateTempContact mb-4`}>
                        <span className="contact__type text-muted">Mumbai Office</span>
                        <span className="contact__fullname text-dark">
                        3rd Floor, Lloyds Centre Point Building.<br/> PLOT - 1096A,<br/> Apasaheb Marathe Marg,<br/> Prabhadevi<br/> Mumbai, Maharashtra, 400025<br/>+91 22 3513 3356
                        </span>
                    </div>
                </aside>
                <aside className="site-footer__offices col-lg-4">
                    <div className={`contact__preview alternateTempContact mb-4`}>
                        <span className="contact__type text-muted">Join Our Team</span>
                        <span className="contact__fullname">
                        <a href={`mailto:jobs.india@wk.com`}>
                                    jobs.india@wk.com
                                </a>
                        </span>

                    </div>
                    </aside>
                </div>
            </div>
            <div className="container-fluid site-footer__legal">
                <div className="row">
                    <aside className="col-auto mr-md-auto">
                    <p>
                        &copy; Wieden Kennedy {new Date().getFullYear()} ·  <Link to="/legal">Legal Stuff</Link>
                    </p>
                    </aside>
                    <nav className="col-auto ml-md-auto">
                    <SocialLinks navClassName={``} />
                    </nav>
                </div>
            </div>
        </footer>
    )
}