import React from "react"
import { Link } from "gatsby"
import { connect } from "react-redux"

import Burger from "./Burger"
import SearchIcon from "./SearchIcon"
import WKLogo from "../svg/wk-logo"

const SiteHeader = props => {
  return(
  <header
    className={`
      site-header
      ${props.NavState.className}
      ${props.SiteHeaderState.className}
    `}
  >
    <hgroup className="container-fluid">
      <div className="row align-items-center">
        <div className="col-auto mr-auto">
          <h1 className={`brand ${props.SiteHeaderState.className}`}>
            <Link to="/">
              <WKLogo />
            </Link>
          </h1>
        </div>
        <nav className="col-auto ml-auto">
          {/* <SearchIcon /> */}
          <Burger />
        </nav>
      </div>
    </hgroup>
  </header>
)}

const mapStateToProps = store => {
  return {
    NavState: store.NavState,
    SiteHeaderState: store.SiteHeaderState,
  }
}

export default connect(mapStateToProps)(SiteHeader)
