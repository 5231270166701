import React from "react"
import { Link } from 'gatsby'

import { connect } from "react-redux"
import { openNav, closeNav } from "../actions"

class Burger extends React.Component {
  constructor(props) {
    super(props)

    // register events
    this.handle = this.handle.bind(this)
  }

  handle() {
    if (
      typeof this.props.NavState.closed == `undefined` ||
      this.props.NavState.closed
    ) {
      this.props.openNav()
    } else {
      this.props.closeNav({
        revertInverted: this.props.SiteHeaderState.inverted,
      })
    }
  }

  render() {
    return (
      <nav className="delhi-nav">
        <ul>
          <li><Link to="/work" partiallyActive={true} activeClassName="is-active">
            Work
        </Link></li>
          <li><Link to="/about/office/delhi" partiallyActive={true} activeClassName="is-active">
            About 
        </Link></li>
        </ul>
      </nav>
    )
  }
}

const mapStateToProps = store => {
  return {
    NavState: store.NavState,
    SiteHeaderState: store.SiteHeaderState,
  }
}

export default connect(
  mapStateToProps,
  {
    openNav,
    closeNav,
  }
)(Burger)
